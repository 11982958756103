<template>
  <div class="layout">
    <div class="title">
      <a-typography-title :level="3">{{$t('overview-page.fleet KPIs')}}</a-typography-title>
      <!-- <a href="">Export report</a> -->
    </div>

    <a-row :gutter="[10, 0]">
      <a-col :span="12" v-if="details.cii_widget">
        <div class="card">
          <div class="card-title">{{$t('overview-page.carbon intensity index (CII)')}}</div>
<!--          <div class="card-line"></div>-->
          <div class="card-body">
            <a-typography-title :level="3">{{$t('overview-page.fleet KPIs')}}</a-typography-title>
            <a-typography-text type="secondary">
              {{$t('by')}} {{ details.cii_widget.YTD.date_end }}
            </a-typography-text>

            <div class="card-inner">
              <div class="card-details">
                <div class="card-statistic largest">
                  <a-statistic
                    class="statistic-largest"
                    :title="$t('units.gCO2/tkm')"
                    :value="kFormatter.format(details.cii_widget.YTD.CII)"
                  >
                    <template #suffix>
                      <a-space align="center">
                        <div class="icon-arrow">
                          <img
                            v-if="details.cii_widget.YTD.YTY !== 0"
                            :src="
                              details.cii_widget.YTD.YTY < 0
                                ? icons.arrowGreenDown
                                : icons.arrowRedUp
                            "
                            alt="arrow"
                          />
                        </div>

                        <sup class="statistic-sup">
                          <a-typography-text
                            v-if="details.cii_widget.YTD.YTY === 0"
                            strong
                          >
                            {{ kFormatter.format(details.cii_widget.YTD.YTY) }}
                          </a-typography-text>

                          <a-typography-text
                            :type="
                              details.cii_widget.YTD.YTY < 0
                                ? 'success'
                                : 'danger'
                            "
                            v-else
                            strong
                          >
                            {{ kFormatter.format(details.cii_widget.YTD.YTY, "%") }}
                          </a-typography-text>
                        </sup>
                      </a-space>
                    </template>
                  </a-statistic>
                </div>

                <div class="past-years">
                  <div class="past-years-title">{{$t('overview-page.past years CII')}}</div>

                  <div
                    class="past-years-item"
                    v-for="(item, index) in Object.keys(details.cii_widget)
                      .map((key) => ({
                        title: key,
                        ...details.cii_widget[key],
                      }))
                      .filter((item) => item.title !== 'YTD')"
                    :key="index"
                  >
                    <a-typography-text strong>
                      {{ item.title }}
                    </a-typography-text>

                    <a-typography-text strong>
                      {{ kFormatter.format(item.CII) }}
                    </a-typography-text>

                    <PercentDirection :value="parseFloat(item.YTY)" />
                  </div>
                </div>
              </div>

              <OverviewChart
                withContent
                :size="23"
                :text="details.cii_widget.YTD.fleet_grade"
                :range="details.cii_widget.YTD.vessels_grade"
              />
            </div>
          </div>
        </div>
      </a-col>

      <a-col :span="12" v-if="details.cii_widget">
        <div class="card">
          <div class="card-title">
            {{$t('overview-page.SEEMP fleet efficiency improvement potential')}}
          </div>
          <div class="card-line"></div>
          <div class="card-body">
            <a-typography-title :level="3">{{$t('overview-page.factors YTD')}}</a-typography-title>
            <a-typography-text type="secondary">
              by {{ details.factors_widget.date_end }}
            </a-typography-text>

            <div class="card-statistic">
              <a-row :gutter="[60, 0]">
                <a-col
                  :span="8"
                  v-if="details.factors_widget.rpm_power_profile_optimization"
                >
                  <a-statistic
                    class="statistic-large"
                    :title="$t('overview-page.RPM/power optimization')"
                    :value="
                      kFormatter.format(
                        details.factors_widget.rpm_power_profile_optimization
                          .savings
                      )
                    "
                  >
                    <template #suffix>%</template>
                  </a-statistic>

                  <div class="card-statistic-group">
                    <a-statistic
                      title="USD"
                      :value="
                        kFormatter.format(
                          details.factors_widget.rpm_power_profile_optimization
                            .USD
                        )
                      "
                    />

                    <a-statistic
                      :title="'CO2, ' + $t('units.ton')"
                      :value="
                        kFormatter.format(
                          details.factors_widget.rpm_power_profile_optimization
                            .CO2
                        )
                      "
                    />
                  </div>
                </a-col>

                <a-col
                  :span="8"
                  v-if="details.factors_widget.jit_arrival_optimization"
                >
                  <a-statistic
                    class="statistic-large"
                    :title="$t('overview-page.JIT arrival')"
                    :value="
                      kFormatter.format(
                        details.factors_widget.jit_arrival_optimization.savings
                      )
                    "
                  >
                    <template #suffix>%</template>
                  </a-statistic>

                  <div class="card-statistic-group">
                    <a-statistic
                      title="USD"
                      :value="
                        kFormatter.format(
                          details.factors_widget.jit_arrival_optimization.USD
                        )
                      "
                    />

                    <a-statistic
                      :title="'CO2, ' + $t('units.ton')"
                      :value="
                        kFormatter.format(
                          details.factors_widget.jit_arrival_optimization.CO2
                        )
                      "
                    />
                  </div>
                </a-col>

                <a-col
                  :span="8"
                  v-if="details.factors_widget.hull_prop_optimization"
                >
                  <a-statistic
                    class="statistic-large is-disabled"
                    :title="$t('overview-page.hull propeller')"
                    :value="
                      kFormatter.format(
                        details.factors_widget.hull_prop_optimization.savings
                      )
                    "
                  >
                    <template
                      v-if="
                        details.factors_widget.hull_prop_optimization.savings >
                        0
                      "
                      #suffix
                      >%</template
                    >
                  </a-statistic>

                  <div class="card-statistic-group">
                    <a-statistic
                      class="is-disabled"
                      title="USD"
                      :value="
                        kFormatter.format(
                          details.factors_widget.hull_prop_optimization.USD
                        )
                      "
                    />

                    <a-statistic
                      class="is-disabled"
                      :title="'CO2, ' + $t('units.ton')"
                      :value="
                        kFormatter.format(
                          details.factors_widget.hull_prop_optimization.CO2
                        )
                      "
                    />
                  </div>
                </a-col>
              </a-row>
            </div>

            <div class="card-achievable">
              {{$t('overview-page.achievable CII')}}
              <Shape
                :background="
                  handleGrade(details.factors_widget.achievable_grade)
                "
                :text="details.factors_widget.achievable_grade"
              />
              <div class="card-achievable-count">
                {{ details.factors_widget.achievable_cii.toFixed(1) }}
              </div>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>

    <a-row :gutter="[10, 0]">
      <a-col :span="12" v-if="details.cii_widget">
        <div class="card">
          <div class="card-body is-black">
            <a-typography-title :level="5"
              >{{$t('overview-page.regulation restriction impact forecast')}}</a-typography-title
            >

            <div class="charts">
              <div
                class="charts-item"
                v-for="(item, index) in details.rrif_widget"
                :key="index"
              >
                <div class="charts-item-title">
                  {{ index }}
                  <img
                    v-if="item.alarm"
                    :src="icons.warningRed"
                    alt="warning"
                  />
                </div>

                <OverviewChart :size="14" :range="item.grades" isSmallShape />
              </div>
            </div>

            <div class="charts-info">
              <a-typography-text type="secondary" strong
                > <br />
              </a-typography-text>

              <a-typography-text type="secondary" strong>
                <a-space align="start" size="middle">
                  <img :src="icons.warningRed" alt="warning" />
                  {{$t('overview-page.ships of your fleet with d and e')}}
		</a-space
                >
              </a-typography-text>
            </div>
          </div>
        </div>
      </a-col>

      <a-col
        :span="12"
        class="flex column space-between"
        v-if="details.factors_widget"
      >
        <a-row :gutter="[10, 0]" class="flex-2 mb-8">
          <a-col :span="24">
            <div class="card">
              <div class="card-body is-black">
                <a-typography-title :level="5">{{$t('overview-page.history')}}</a-typography-title>

                <div class="card-statistic">
                  <a-row :gutter="[60, 0]">
                    <!-- <a-col :span="8">
                  <a-statistic class="statistic-middle" title="YTD" :value="5">
                    <template #suffix>
                      <a-space align="center">
                        <a-typography-text>%</a-typography-text>

                        <div class="icon-arrow">
                          <img :src="icons.arrowGreenDown" alt="arrow" />
                        </div>

                        <sup class="statistic-sup">
                          <a-typography-text type="success">
                            -0.2%
                          </a-typography-text>
                        </sup>
                      </a-space>
                    </template>
                  </a-statistic>

                  <div class="card-statistic-group">
                    <a-statistic title="USD" :value="800">
                      <template #suffix>K</template>
                    </a-statistic>

                    <a-statistic title="CO2, ton" :value="45">
                      <template #suffix>K</template>
                    </a-statistic>
                  </div>
                </a-col> -->

                    <a-col
                      :span="8"
                      v-for="(item, index) in Object.keys(
                        details.history_widget
                      )
                        .reverse()
                        .map((key) => ({
                          title: key,
                          ...details.history_widget[key],
                        }))"
                      :key="index"
                    >
                      <a-statistic
                        class="statistic-middle"
                        :title="item.title"
                        :value="item.Savings.toFixed(1)"
                      >
                        <template #suffix>
                          <a-space align="center">
                            <a-typography-text>%</a-typography-text>

                            <div class="icon-arrow">
                              <img
                                v-if="item.YTY !== 0"
                                :src="
                                  item.YTY < 0
                                    ? icons.arrowGreenDown
                                    : icons.arrowRedUp
                                "
                                alt="arrow"
                              />
                            </div>

                            <sup class="statistic-sup">
                              <a-typography-text
                                v-if="item.YTY !== 0"
                                :type="item.YTY < 0 ? 'success' : 'danger'"
                                strong
                              >
                                {{ item.YTY.toFixed(1).replace("-", "") }}%
                              </a-typography-text>
                            </sup>
                          </a-space>
                        </template>
                      </a-statistic>

                      <div class="card-statistic-group">
                        <a-statistic
                          title="USD"
                          :value="kFormatter.format(item.USD)"
                        />
                        <a-statistic
                          :title="'CO2, ' + $t('units.ton')"
                          :value="kFormatter.format(item.CO2)"
                        />
                      </div>
                    </a-col>

                    <!-- <a-col :span="8">
                  <a-statistic class="statistic-middle" title="2020" :value="6">
                    <template #suffix>%</template>
                  </a-statistic>

                  <div class="card-statistic-group">
                    <a-statistic title="USD" :value="800">
                      <template #suffix>K</template>
                    </a-statistic>

                    <a-statistic title="CO2, ton" :value="45">
                      <template #suffix>K</template>
                    </a-statistic>
                  </div>
                </a-col> -->
                  </a-row>
                </div>
              </div>
            </div>
          </a-col>
        </a-row>

        <a-row :gutter="[10, 0]" class="flex-1">
          <a-col :span="24">
            <div class="card">
              <div class="card-body is-black">
                <a-typography-title :level="5">
                  <a-typography-text type="secondary"
                    >{{$t('overview-page.KPIs data sources')}}</a-typography-text
                  >
                </a-typography-title>

                <div class="ships-statistic">
                  <div class="ship-item">
                    <a-typography-text type="secondary"
                      >{{$t('overview-page.tracking')}}</a-typography-text
                    >

                    <div class="ship-item-inner">
                      <img :src="icons.ship" alt="ship" />
                      <div class="ship-count">
                        {{ data_sources.tracking ?? 0 }}
                      </div>
                    </div>
                  </div>

                  <div class="ship-item">
                    <a-typography-text type="secondary"
                      >{{$t('overview-page.noons')}}</a-typography-text
                    >

                    <div class="ship-item-inner">
                      <img :src="icons.ship" alt="ship" />
                      <div class="ship-count">
                        {{ data_sources.noons ?? 0 }}
                      </div>
                    </div>
                  </div>

                  <div class="ship-item">
                    <a-typography-text type="secondary"
                      >{{$t('overview-page.sensors')}}</a-typography-text
                    >

                    <div class="ship-item-inner">
                      <img :src="icons.ship" alt="ship" />
                      <div class="ship-count">
                        {{ data_sources.sensors ?? 0 }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { defineComponent, computed, onMounted } from "vue";
import useDashboard from "@/composables/useDashboard";
import useAuth from "@/composables/useAuth";
import { OverviewChart } from "./components";

import { icons } from "@/assets";
import { kFormatter } from "@/helpers";

export default defineComponent({
  name: "Overview",

  setup() {
    const auth = useAuth();
    const dashboard = useDashboard();
    const details = computed(() => dashboard.details());
    const data_sources = computed(() => dashboard.data_sources());

    onMounted(() => {
      auth.fetch();
      dashboard.fetchDashboard();
      dashboard.fetchDataSources();
    });

    const handleGrade = (grade) => {
      if (grade === "A") {
        return "#00bc4b";
      } else if (grade === "B") {
        return "#89b900";
      } else if (grade === "C") {
        return "#e8e800";
      } else if (grade === "D") {
        return "#ffc000";
      } else if (grade === "E") {
        return "#ee3440";
      }
    };

    return {
      details,
      data_sources,
      icons,
      kFormatter,
      handleGrade,
    };
  },

  components: {
    OverviewChart,
  },
});
</script>

<style lang="less" scoped>
.layout {
  padding: 1rem;
}

.flex {
  display: flex;

  &.column {
    flex-direction: column;
  }

  &.space-between {
    justify-content: space-between;
  }
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.mb-8 {
  margin-bottom: 8px;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.8rem;
  text-transform: uppercase;
}

.icon-arrow {
  display: flex;
  align-items: center;

  img {
    height: 3rem;

    .media-xl({
      height: 1.8rem;
    });
  }
}

.card {
  background-color: @color-white;
  box-shadow: 0 0.2rem 0.4rem rgba(@color-black, 0.1);
  margin-bottom: 1rem;
  height: 100%;

  &-title {
    color: @color-secondary;
    font-size: 1.8rem;
    padding: 1.4rem;
    height: 4.8rem;

    .media-xl({
      font-size: 1.6rem;
    });
  }
  //&-line{
  //  border-top: 0.1rem solid @color-border;
  //}

  &-body {
    background-color: @color-white;//lighten(@color-black, 2%);
    box-shadow: 0 0.2rem 0.4rem rgba(@color-black, 0.1);
    padding: 0rem 3.6rem;
    position: relative;
    z-index: 2;
    height: calc(100% - 60px);

    &.is-black {
      background-color: @color-white;
      box-shadow: none;
      position: relative;
      z-index: 1;
      height: 100%;
    }

    h3.ant-typography,
    h5.ant-typography {
      font-weight: 400;
      padding-top: 1.5rem;
      line-height: 1;
      color: @text-color;
    }
  }

  &-inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
  }

  &-charts {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &-statistic {
    margin-top: 3.2rem;

    .statistic-sup {
      font-size: 1.6rem;
      position: relative;
      top: -1.8rem;

      .media-xl({
        font-size: 1.2rem;
      });
    }

    &-group {
      border-top: 0.1rem solid @color-border;
      display: flex;
      align-items: center;
      margin-top: 1.6rem;

      .ant-statistic {
        padding-top: 1.2rem;
        line-height: 1.2;
        flex: 1;

        &:not(:first-child) {
          border-left: 0.1rem solid @color-border;
          padding-left: 1.6rem;
        }
      }
    }

    &.largest {
      border-bottom: 0.1rem solid @color-border;
      padding-bottom: 2.4rem;
      margin-bottom: 2rem;

      .ant-statistic {
        display: flex;
        flex-direction: column-reverse;
      }

      .icon-arrow {
        img {
          height: 5.1rem;

          .media-xl({
            height: 2.4rem;
          });
        }
      }

      .statistic-sup {
        top: -4rem;
      }
    }
  }

  &-achievable {
    border-top: 0.1rem solid @color-border;
    display: flex;
    align-items: center;
    font-size: 1.8rem;
    padding-top: 1.2rem;
    margin-top: 4.2rem;
    padding-bottom: 1.2rem;
    gap: 0.8rem;

    &-count {
      font-size: 2.8rem;

      .media-xl({
        font-size: 2.1rem;
      });
    }

    .media-xl({
      font-size: 1.6rem;
    });
  }
}

.past-years {
  &-title {
    color: @color-secondary;
    font-size: 1.8rem;
    margin-bottom: 0.8rem;

    .media-xl({
      font-size: 1.6rem;
    });
  }

  &-item {
    display: flex;
    align-items: center;
    margin-bottom: 0.8rem;
    flex: 1;
    gap: 0.4rem;

    .icon-arrow {
      img {
        height: 1.2rem;
      }
    }

    span {
      width: 4.8rem;
    }
  }
}

.charts {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  margin-top: 1.6rem;

  &-item {
    &-title {
      font-size: 2rem;
      display: flex;
      align-items: center;
      gap: 0.4rem;
      margin-bottom: 1.5rem;
    }

    img {
      width: 1.8rem;
      height: 1.8rem;
    }
  }

  &-info {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 5rem;

    .ant-typography {
      max-width: 36rem;
    }
  }
}

.ships-statistic {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4.8rem;
  margin-top: 1.6rem;

  .ship-item {
    display: flex;
    flex-direction: column;
    text-align: center;

    &-inner {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      margin-top: 0.8rem;
    }

    .ant-typography {
      font-size: 1.6rem;
    }

    img {
      width: 2.2rem;
    }
  }
}
</style>
