import { useStore } from 'vuex';

import axios from 'axios';

export default () => {
  const store = useStore();

  const fetchDashboard = () => {
    return axios.get('/api/dashboard').then(({ data }) => {
      store.commit('dashboard/setDetails', data);
    });
  };

  const fetchDataSources = () => {
    return axios.get('/api/data_sources').then(({ data }) => {
      store.commit('dashboard/setDataSources', data);
    });
  };

  const details = () => {
    return store.state.dashboard.details;
  };

  const data_sources = () => {
    return store.state.dashboard.data_sources;
  };

  return {
    fetchDashboard,
    fetchDataSources,
    details,
    data_sources,
  };
};
