<template>
  <div class="chart">
    <div class="chart-container">
      <div class="chart-inner" :style="`width: ${size}rem`">
        <vue-echarts
          :option="option"
          :style="`height: ${size}rem`"
          autoresize
        />
      </div>

      <Shape
        v-if="withContent"
        class="shape"
        :background="handleGrade(text)"
        color="#FFFFFF"
        :text="text"
        isLarge
      />
    </div>

    <div :class="['ships-grade', { 'is-small': isSmallShape }]">
      <div class="ships-grade-title" v-if="withContent">{{$t('overview-page.ships grade')}}</div>

      <div :class="['ships-grade-item', { 'is-disabled': range.A === 0 }]">
        <Shape background="#00bc4b" text="A" />

        <a-typography-text>{{ range.A }}</a-typography-text>
      </div>

      <div :class="['ships-grade-item', { 'is-disabled': range.B === 0 }]">
        <Shape background="#89b900" text="B" />

        <a-typography-text>{{ range.B }}</a-typography-text>
      </div>

      <div :class="['ships-grade-item', { 'is-disabled': range.C === 0 }]">
        <Shape background="#DEDE3B" text="C" />

        <a-typography-text>{{ range.C }}</a-typography-text>
      </div>

      <div :class="['ships-grade-item', { 'is-disabled': range.D === 0 }]">
        <Shape background="#FFA800" text="D" />

        <a-typography-text>{{ range.D }}</a-typography-text>
      </div>

      <div :class="['ships-grade-item', { 'is-disabled': range.E === 0 }]">
        <Shape background="#ee3440" text="E" />

        <a-typography-text>{{ range.E }}</a-typography-text>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { VueEcharts } from "vue3-echarts";

export default defineComponent({
  name: "OverviewChart",

  props: {
    withContent: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 28.6,
    },
    isSmallShape: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "B",
    },
    range: {
      type: Object,
      default: () => ({
        A: 0,
        B: 0,
        C: 0,
        D: 0,
        E: 0,
      }),
    },
  },

  setup(props) {
    const handleGrade = (grade) => {
      if (grade === "A") {
        return "#00bc4b";
      } else if (grade === "B") {
        return "#89b900";
      } else if (grade === "C") {
        return "#DEDE3B";
      } else if (grade === "D") {
        return "#FFA800";
      } else if (grade === "E") {
        return "#ee3440";
      }
    };

    return {
      handleGrade,
      option: {
        color: ["#00bc4b", "#89b900", "#DEDE3B", "#ffc000", "#ee3440"],
        series: [
          {
            type: "pie",
            radius: ["45%", "70%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderColor: "#FFFFFF",
              borderWidth: 1,
            },
            labelLine: {
              show: false,
            },
            silent: true,
            data: Object.values(props.range).map((value) => ({ value })),
          },
        ],
      },
    };
  },

  components: {
    VueEcharts,
  },
});
</script>

<style lang="less" scoped>
.chart {
  display: flex;
  align-items: flex-start;

  &-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .shape {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: @color-white;
    }

    .vue-echarts {
      transform: scale(1.4);

      .media-xl({
        transform: scale(1.1);
      });
    }
  }

  .ships-grade {
    margin-left: 5rem;

    &-title {
      color: @color-secondary;
      font-size: 1.8rem;
    }

    &-item {
      display: flex;
      align-items: center;
      margin-top: 1.6rem;
      gap: 1.8rem;
      flex: 1;
      color: @color-white;

      &.is-disabled {
        opacity: 0.4;
      }

      .ant-typography {
        font-size: 1.6rem;
      }
    }

    &.is-small {
      margin-left: 1.8rem;

      .ships-grade-item {
        margin-top: 0.95rem;
        gap: 1rem;
      }
    }
  }
}
</style>
